<template>
  <div
    v-if="Object.keys(profileData).length"
    id="user-profile"
  >
    <!-- <profile-header :header-data="profileData.header" /> -->
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <!-- about suggested page and twitter feed -->
        <b-col
          lg="3"
          cols="12"
          order="1"
          order-lg="1"
        >
          <profile-about :about-data="profileData" />
          <!-- <skor :data-profile="profileData" /> -->
          <profile-polls :polls-data="profileData.polls" />
          <keterampilan :data-profile="profileData" />
          <!-- <profile-twitter-feed :twitter-feed="profileData.twitterFeeds" /> -->
        </b-col>
        <!--/ about suggested page and twitter feed -->

        <!-- post -->
        <b-col
          lg="9"
          cols="12"
          order="2"
          order-lg="2"
        >
          <list
            :user-data="profileData"
          />
          <!-- <profile-post :posts="profileData.post" /> -->
        </b-col>
        <!-- post -->

        <!-- latest photos suggestion and polls -->
        <!-- <b-col
          lg="3"
          cols="12"
          order="3"
        > -->
          <!--
          <profile-suggestion :suggestions="profileData.suggestions" /> -->
        <!-- </b-col> -->
        <!--/ latest photos suggestion and polls -->

        <!-- load more  -->
        <b-col
          cols="12"
          order="4"
        >
          <profile-bottom />
        </b-col>
        <!--/ load more  -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import store from '@/store'
import storeModule from '@/connection-api/user/storeModule'
import ProfileHeader from './ProfileHeader.vue'
import ProfileAbout from './ProfileAbout.vue'
import Keterampilan from './Keterampilan.vue'
import ProfileTwitterFeed from './ProfileTwitterFeed.vue'
import ProfilePost from './ProfilePost.vue'
// import Skor from './Skor.vue'
import ProfileSuggestion from './ProfileSuggestion.vue'
import ProfilePolls from './ProfilePolls.vue'
import profileBottom from './profileBottom.vue'
import List from './kegiatan/List.vue'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,

    // ProfileHeader,
    ProfileAbout,
    Keterampilan,
    List,
    // ProfileTwitterFeed,
    // ProfilePost,
    // Skor,
    // ProfileSuggestion,
    // ProfilePolls,
    // profileBottom,
  },
  data() {
    return {
      profileData: { },
      optionsLocal: {},
      profileFile: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      userId: null,
      /* eslint-disable global-require */
      avatar: require('@/assets/images/avatars/default.png'),
      /* eslint-disable global-require */
    }
  },
  unmounted() {
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  },
  async mounted() {
    if (this.$route.params.id) {
      this.userId = this.$route.params.id
    } else {
      this.userId = this.userData.id
    }
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    store.dispatch('pengguna/fetchData', { id: this.userId })
      .then(response => {
        this.profileData = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.profileData = {}
        }
      })
  },
  created() {
    // this.$http.get('/profile/data').then(res => { this.profileData = res.data })
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
