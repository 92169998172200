<template>
  <content class="blog-wrapper">
    <div class="group-area mt-1">
      <h4>Kegiatan yang di ikuti {{ userData.name }}</h4>
    </div>
    <!-- <div class="group-area mt-1">
      <b-link >
        <b-badge
          pill
          class="mr-75"
          variant="primary"
        >
          Semua
        </b-badge>
      </b-link>
      <b-link >
        <b-badge
          pill
          class="mr-75"
          variant="light-dark"
        >
          Pendakian
        </b-badge>
      </b-link>
      <b-link >
        <b-badge
          pill
          class="mr-75"
          variant="light-dark"
        >
          Olahraga Air
        </b-badge>
      </b-link>
      <b-link >
        <b-badge
          pill
          class="mr-75"
          variant="light-dark"
        >
          Outbound
        </b-badge>
      </b-link>
      <b-link >
        <b-badge
          pill
          class="mr-75"
          variant="light-dark"
        >
          Charity
        </b-badge>
      </b-link>
      <b-link >
        <b-badge
          pill
          class="mr-75"
          variant="light-dark"
        >
          Sepak Bola
        </b-badge>
      </b-link>
      <hr>
    </div> -->
    <!-- sidebar -->
    <!-- <div
      slot="sidebar"
      class="blog-sidebar py-2 py-lg-0"
    > -->
      <!-- input search -->
      <!-- <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search-input"
            v-model="search_query"
            placeholder="Search here"
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon
              icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group> -->
      <!--/ input search -->

      <!-- recent posts -->
      <!-- <div class="blog-recent-posts mt-3">
        <h6 class="section-label mb-75">
          Recent Posts
        </h6>
        <b-media
          v-for="(recentpost,index) in blogSidebar.recentPosts"
          :key="recentpost.img"
          no-body
          :class="index? 'mt-2':''"
        >
          <b-media-aside class="mr-2">
            <b-link :to="{ name: 'pages-blog-detail', params:{ id :recentpost.id } }">
              <b-img
                :src="recentpost.img"
                :alt="recentpost.img.slice(6)"
                width="100"
                rounded
                height="70"
              />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="blog-recent-post-title">
              <b-link               :to="{ name: 'pages-blog-detail', params:{ id :recentpost.id } }"
                class="text-body-heading"
              >
                {{ recentpost.title }}
              </b-link>
            </h6>
            <span class="text-muted mb-0">
              {{ recentpost.createdTime }}
            </span>
          </b-media-body>
        </b-media>
      </div> -->
      <!--/ recent posts -->

      <!-- categories -->
      <!-- <div class="blog-categories mt-3">
        <h6 class="section-label mb-1">
          Categories
        </h6>

        <div
          v-for="category in blogSidebar.categories"
          :key="category.icon"
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-link>
            <b-avatar
              rounded
              size="32"
              :variant="tagsColor(category.category)"
              class="mr-75"
            >
              <feather-icon
                :icon="category.icon"
                size="16"
              />
            </b-avatar>
          </b-link>
          <b-link>
            <div class="blog-category-title text-body">
              {{ category.category }}
            </div>
          </b-link>
        </div>
      </div> -->
      <!--/ categories -->
    <!-- </div> -->
    <!--/ sidebar -->
    <b-row class="blog-list-wrapper mt-1">
      <b-col
        v-for="data in kegiatanList.data"
        :key="data.id"
        md="6"
      >
        <meetup
          :dataMeetup="data"
          :userData="userData"
        />
      </b-col>
      <!-- pagination -->
      <b-col cols="12">
        <div class="my-2">
          <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="rows"
            :per-page="perPage"
            @change="handlePageChange"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
  </content>
</template>

<script>
import {
  BRow, BCol, BCard, BFormInput, BButton, BCardText, BCardTitle, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import {
  getAllKegiatanByUser,
} from '@/api/kegiatan'
import Meetup from '../../../kegiatan/list/Meetup.vue'
// import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    // BCard,
    // BFormInput,
    // BCardText,
    // BCardBody,
    // BCardTitle,
    // BMedia,
    // BAvatar,
    // BMediaAside,
    // BMediaBody,
    // BLink,
    // BBadge,
    // BButton,
    // BFormGroup,
    // BInputGroup,
    // BInputGroupAppend,
    // BImg,
    BPagination,
    // ContentWithSidebar,
    Meetup,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      search_query: '',
      blogList: [],
      projectList: [],
      courseList: [],
      kegiatanList: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 9,
      rows: 0,
    }
  },
  created() {
    /*
    this.$http.get('/blog/list/kegiatan').then(res => { this.kegiatanList = res.data })
    this.$http.get('/blog/list/data').then(res => { this.blogList = res.data })
    this.$http.get('/blog/list/project').then(res => { this.projectList = res.data })
    this.$http.get('/blog/list/course').then(res => { this.courseList = res.data })
    this.$http.get('/blog/list/data/sidebar').then(res => { this.blogSidebar = res.data })
    */
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-success'
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getDataPage()
    },
    async getDataPage() {
      const response = await getAllKegiatanByUser(this.currentPage, this.userData.id)
      this.kegiatanList = response.data
      this.kegiatanList.data.forEach((value, index) => {
        if (value.img) {
          this.kegiatanList.data[index].img = `${process.env.VUE_APP_API_URL}/kegiatan/download/${value.img}`
        } else {
          this.kegiatanList.data[index].img = this.defaultImage
        }
      })
      this.rows = response.data.total
    },
  },
  async mounted() {
    try {
      this.show = true
      this.getDataPage()
      this.show = false
    } catch (error) {
      // console.log(error.response.data)
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
