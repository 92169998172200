import {
  get, post, hapus, update, upload, download,
} from './helpers'

function getAllKegiatan(page, data) {
  return get(`/kegiatan/list/${page}`, data)
}

function getAllKegiatanByProyek(page, proyekId) {
  return get(`/kegiatan/listoption/${page}`, { proyek_id: proyekId })
}

function getAllKegiatanByUser(page, userId) {
  return get(`/kegiatan/list/${page}`, { user_id: userId })
}

function getAllKegiatanSidebar(perPage) {
  return get(`/kegiatan/list/${perPage}`)
}

function getAllAplikasi() {
  return get('/aplikasi/list')
}

function getKegiatan(kegiatanId) {
  return get(`/kegiatan/${kegiatanId}`)
}

function getUserKegiatan(kegiatanId, jenisUser, data) {
  return get(`/kegiatan/list-user/${kegiatanId}/${jenisUser}`, data)
}

function getListUserKegiatan(kegiatanId, jenisUser, data) {
  return get(`/kegiatan/list-user-kegiatan/${kegiatanId}/${jenisUser}`, data)
}

function getListLinkKegiatan(kegiatanId) {
  return get(`/kegiatan/list-link-kegiatan/${kegiatanId}`)
}

function getListRatingKegiatan(kegiatanId) {
  return get(`/kegiatan/list-rating-kegiatan/${kegiatanId}`)
}

function insKegiatan(data) {
  return post('/kegiatan', data)
}

function uploadFileKegiatan(file, metadata, onUploadProgress) {
  return upload('/kegiatan/uploadfile', file, metadata, onUploadProgress)
}

function insUserKegiatan(data) {
  return post('/kegiatan/userKegiatan', data)
}

function insLinkKegiatan(data) {
  return post('/kegiatan/linkKegiatan', data)
}

function updRatingKegiatan(data) {
  return post('/kegiatan/ratingKegiatan', data)
}

function delUserKegiatan(kegiatanId, UserId, softDelete) {
  return hapus(`/kegiatan/userKegiatan/${kegiatanId}/${UserId}/${softDelete}`)
}

function delLinkKegiatan(kegiatanId, LinkId, softDelete) {
  return hapus(`/kegiatan/linkKegiatan/${kegiatanId}/${LinkId}/${softDelete}`)
}

function ubahStatusUserKegiatan(kegiatanId, UserId, status) {
  return get(`/kegiatan/ubahStatusUserKegiatan/${kegiatanId}/${UserId}/${status}`)
}

function ubahStatusUserKegiatanMass(data, kegiatanId, status) {
  return post(`/kegiatan/ubahStatusUserKegiatanMass/${kegiatanId}/${status}`, data)
}

function ubahLinkKegiatan($data) {
  return update('/kegiatan/linkKegiatan', $data)
}

function ubahKegiatan($data) {
  return update('/kegiatan', $data)
}

function deleteKegiatan($data) {
  return hapus('/kegiatan', $data)
}

function getRoleUser() {
  return get('/kegiatan/role/user')
}

function exportExcelDaftarUserKegiatan($data) {
  return download('daftar-user-kegiatan/exportExcel', $data)
}

/* eslint-disable import/prefer-default-export */
export {
  getAllKegiatan,
  getAllKegiatanByProyek,
  getAllKegiatanByUser,
  getAllKegiatanSidebar,
  getAllAplikasi,
  getKegiatan,
  getUserKegiatan,
  getListUserKegiatan,
  getListLinkKegiatan,
  getListRatingKegiatan,
  insKegiatan,
  insUserKegiatan,
  insLinkKegiatan,
  updRatingKegiatan,
  delUserKegiatan,
  delLinkKegiatan,
  ubahKegiatan,
  ubahStatusUserKegiatan,
  ubahLinkKegiatan,
  uploadFileKegiatan,
  ubahStatusUserKegiatanMass,
  getRoleUser,
  exportExcelDaftarUserKegiatan,
  deleteKegiatan,
}
