<template>
  <b-card>
    <!-- about -->
    <div class="mb-2"
    >
      <h5 class="text-capitalize mb-75">
        Nama
      </h5>
      <b-card-text>
        {{ aboutData.name }}
      </b-card-text>

      <h5 class="text-capitalize mb-75">
        Umur
      </h5>
      <b-card-text>
        {{ aboutData.member.age }}
      </b-card-text>

      <h5 class="text-capitalize mb-75">
        Jenis Kelamin
      </h5>
      <b-card-text
        v-if="aboutData.member.gender"
      >
        {{ aboutData.member.gender.label }}
      </b-card-text>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import { title } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
  },
  methods: {
    title,
  },
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    // console.log(this.aboutData)
  },
}
</script>
