<template>
  <b-card>
    <h5 class="mb-1">
      Survey Kegiatan
    </h5>
    <b-card-text>Apakah kegiatan kemarin dapat dimengerti?</b-card-text>

    <div
      v-for="data in pollsData"
      :key="data.name"
      class="polls-info mt-2"
    >
      <b-card-text>{{ data.name }}</b-card-text>
      <div
        v-for="data2 in data.answer"
        :key="data2.name"
        class="polls-info mt-2"
      >
        <!-- radio -->
        <div class="d-flex justify-content-between">
          <b-form-radio
            v-model="Selected"
            name="polls"
            :value="data2.username"
            class="mb-25"
          >
            {{ data2.name }}
          </b-form-radio>
          <div class="text-right">
            {{ data2.result }}
          </div>
        </div>
        <!--/ radio -->

        <!-- progress -->
        <b-progress
          :value="data2.result"
          max="100"
          height="6px"
          class="my-50"
        />
        <!--/ progress -->

        <!-- avatar group -->
        <!-- <b-avatar-group
          size="26"
          class="mt-1"
        >
          <b-avatar
            v-for="avatar in data.votedUser"
            :key="avatar.img"
            v-b-tooltip.hover.bottom="avatar.username"
            :src="avatar.img"
            class="pull-up"
          />
        </b-avatar-group> -->
        <!--/ avatar group -->
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BFormRadio, BProgress, BAvatarGroup, BCardText, BAvatar, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BFormRadio,
    BProgress,
    // BAvatarGroup,
    BCardText,
    // BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    pollsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      Selected: '',
    }
  },
}
</script>
