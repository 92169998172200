<template>
  <b-card>
    <h3 class="mb-2">
      Keterampilan
    </h3>

    <b-row class="blog-list-wrapper mt-1">
      <b-col
        v-for="data in optionsLocal.keterampilan"
        :key="data.id"
        md="12"
      >
        <b-card-text><h5>{{data.nama}}</h5></b-card-text>
        <div class="blog-list-wrapper mb-2">
          <div
            v-for="item in data.item"
            :key="item.id"
            md="5"
          >
            <b-button
              v-if="item.active"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mt-1 mr-1"
            >
              <feather-icon icon="CheckIcon" />
              {{item.nama}}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BRow,
  BCol,
  BCardText,
  BButton,
} from 'bootstrap-vue'
import store from '@/store'
import storeModule from '@/connection-api/user/storeModule'

export default {
  components: {
    BCard,
    // BAvatar,
    BRow,
    BCol,
    BCardText,
    // BButton,
    // BAvatar,
  },
  props: {
    dataProfile: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      userId: null,
    }
  },
  unmounted() {
    const USER_APP_STORE_MODULE_NAME = 'pengguna'
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  },
  async mounted() {
    if (this.$route.params.id) {
      this.userId = this.$route.params.id
    } else {
      this.userId = this.userData.id
    }
    this.reloadData()
  },
  methods: {
    reloadData() {
      const USER_APP_STORE_MODULE_NAME = 'pengguna'
      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

      store.dispatch('pengguna/fetchDataKeterampilan', { id: this.userId })
        .then(response => {
          this.optionsLocal = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.optionsLocal = {}
          }
        })
    },
  },
}
</script>
