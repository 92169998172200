<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <div class="bg-light-primary rounded-top text-center">
      <b-img
        v-if="dataMeetup.img"
        :src="dataMeetup.img"
        class="card-img-top"
        width="100"
      />
      <b-img
        v-else
        :src="defaultImage"
        class="card-img-top"
        width="150"
      />
    </div>
    <b-card-body>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <h6 class="mb-0">
            {{ localDataDay }}
          </h6>
          <h3 class="mb-0">
            {{ localDataDate }}
          </h3>
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ dataMeetup.nama }}
          </b-card-title>
          <!-- <b-card-text class="mb-0">

          </b-card-text> -->
        </div>
      </div>
      <!--/ metting header -->

      <b-badge
        v-if="isExist(dataMeetup)"
        class="mb-2"
        variant="primary"
      >
        Saya terlibat dalam kegiatan ini.
      </b-badge>

      <!-- media -->
      <b-media
        v-for="media in mediaData"
        :key="media.avatar"
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              :icon="media.avatar"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mb-0">
            {{ media.title }}
          </h6>
          <small>{{ media.subtitle }}</small>
        </b-media-body>
      </b-media>

      <!-- avatar group -->
      <b-avatar-group
        class="mt-2 pt-50"
        size="33"
      >
        <b-avatar
          v-for="avatar in dataMeetup.user_kegiatan_all"
          :key="avatar.avatar"
          v-b-tooltip.hover.bottom="avatar.name"
          :src="avatar.avatar"
          :to="{ name: 'pages-profile-detail', params: {id: avatar.user_id}}"
          class="pull-up"
        />
      </b-avatar-group>
      <b-avatar-group
        class="mt-2 pt-50"
        size="33"
      >
        <b-badge
          style="margin-right: 2px; margin-top: 2px;"
          v-for="data in dataMeetup.public_tag"
          :key="data.id"
          variant="success"
        >
          {{data.nama}}
        </b-badge>
      </b-avatar-group>
      <hr>
      <div class="d-flex justify-content-between align-items-center">
        <b-link
          :to="{ name: 'pages-kegiatan-detail', params: { id: dataMeetup.id } }"
          class="font-weight-bold"
        >
          Lihat Selengkapnya
        </b-link>
        <b-link
          v-if="canUpdate(dataMeetup)"
          :to="{ name: 'pages-kegiatan-ubah', params: { id: dataMeetup.id } }"
          class="font-weight-bold"
        >
          <feather-icon
            icon="EditIcon"
          />
        </b-link>
        <b-link
          v-if="canDelete(dataMeetup)"
          class="font-weight-bold"
          @click="deleteData(dataMeetup.id)"
        >
          <feather-icon
            icon="Trash2Icon"
          />
        </b-link>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardBody, BCardText, BBadge, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
  BLink,
} from 'bootstrap-vue'
import {
  deleteKegiatan,
} from '@/api/kegiatan'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    // BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BLink,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['dataMeetup', 'onlyView'],
  data() {
    return {
      localDataDay: this.dataMeetup.day, // moment(this.dataMeetup.tanggal_mulai).format('ddd'),
      localDataDate: moment(this.dataMeetup.tanggal_mulai).format('DD'),
      mediaData: [
        { avatar: 'BriefcaseIcon', title: 'Aksi', subtitle: this.dataMeetup.proyek.nama },
        { avatar: 'CalendarIcon', title: 'Tanggal', subtitle: this.dataMeetup.tanggal },
        { avatar: 'MapPinIcon', title: 'Lokasi', subtitle: this.dataMeetup.lokasi },
      ],
      /* eslint-disable global-require */
      defaultImage: require('@/assets/images/avatars/default.png'),
      /* eslint-disable global-require */
      avatars: [],
    }
  },
  methods: {
    async deleteData(param) {
      if (window.confirm('Apakah anda yakin?')) {
        const dataPost = { id: param, soft_delete: 1 }
        const response = await deleteKegiatan(dataPost)
        if (response.data.message === 'error') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: 'Tidak dapat menghapus data!',
            },
          })
        } else if (response.data.message === 'success') {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sukses',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil dihapus',
            },
          })
          this.$router.go()
        }
      }
    },
    isExist(data) {
      if (this.onlyView === true) {
        return false
      }
      if (data.user_proyek && data.user_proyek[0] && data.user_proyek[0].status_approval_id === 2) {
        return true
      }
      // dataMeetup.user_kegiatan && dataMeetup.user_kegiatan[0] &&
      if (data.user_kegiatan && data.user_kegiatan[0] && data.user_kegiatan[0].status_approval_id === 2) {
        return true
      }
      return false
    },
    canDelete(data) {
      if (this.onlyView === true) {
        return false
      }
      if (this.$can('manage', 'all')) {
        return true
      }
      if (data.user_proyek && data.user_proyek[0] && this.$can('delete', 'kegiatan') && data.user_proyek[0].status_approval_id === 2) {
        return true
      }
      if (data.user_kegiatan && data.user_kegiatan[0] && this.$can('delete', 'kegiatan') && data.user_kegiatan[0].status_approval_id === 2) {
        return true
      }
      return false
    },
    canUpdate(data) {
      if (this.onlyView === true) {
        return false
      }
      if (this.$can('manage', 'all')) {
        return true
      }
      if (data.user_proyek && data.user_proyek[0] && this.$can('update', 'kegiatan') && data.user_proyek[0].status_approval_id === 2) {
        return true
      }
      if (data.user_kegiatan && data.user_kegiatan[0] && this.$can('update', 'kegiatan') && data.user_kegiatan[0].status_approval_id === 2) {
        return true
      }
      return false
    },
  },
}
</script>
